import React, { Component } from "react";
import { graphql } from "gatsby";

import App from "../components/App";
import Hero from "../components/Hero";
import Content from "../components/Content";
import CTA from "../components/CTA";
import Footer from "../components/Footer";
import SEO from "../components/seo";

import persosaImage from "../assets/images/persosa-og.jpg";

class IndexPage extends Component {

  render() {
    const { cta, description, hero, content, title, } = this.props.data.allContentfulPage.edges[0].node;
    const navbarData = this.props.data.allContentfulNavbar.edges[0].node;
    const footerData = this.props.data.allContentfulFooter.edges[0].node;

		// Don't need this yet - right now, we're
		// forcing the OG image to display for the homepage SEO
    // const returnImage = () => {
    //   if (
    //     hero.backgroundImage &&
    //     hero.backgroundImage.file &&
    //     hero.backgroundImage.file.url
    //   ) {
    //     return hero.backgroundImage.file.url
    //   } else {
    //     return persosaImage
    //   }
    // }
    return (
      <App navbar={navbarData}>
        <SEO title={title} description={description.internal.content} image={"/" + persosaImage}/>
				<Hero navbar={navbarData} hero={hero} isHome={true} />
				<Content content={content} />
        <CTA cta={cta} />
				<Footer footer={footerData} />
      </App>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query homepageQuery {
    allContentfulPage(filter: {slug: {eq: "''"}}) {
      edges {
        node {
          id
          title
          description {
            internal {
              content
            }
          }
          cta {
            title
            buttonCTA {
              link
              label
            }
          }
          hero {
            title
            label
            backgroundImage {
              file {
                url
              }
            }
            backgroundVideo {
              backgroundVideo
            }
            content {
              json
            }
          }
          content {
            title
            centerAlignText
            fontSize
            topPadding
            bottomPadding
            narrowColumn
            color
						verticalAlignment
						flipColumnsOnMobile
						extraClasses
            column1Content {
              json
            }
            column2Content {
              json
            }
            column3Content {
              json
            }
            column4Content {
              json
            }
          }
        }
      }
    }
    allContentfulNavbar {
      edges {
        node {
          id
          menus {
            title
            titleLink
            links {
              url
              title
            }
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          id
          menus {
            title
            titleLink
            links {
              url
              title
            }
          }
          colophon
        }
      }
    }
  }
`
